import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"

export const IndexPage = ({ data: { site: { siteMetadata: { title, aboutMe, siteUrl } } }, location: { pathname } }) =>
  <Layout>
    <Helmet
      title={`About | ${title}`}
      meta={[
        { property: 'og:title', content: `About | ${title}` },
        { property: 'og:url', content: `${siteUrl}${pathname}` },
      ]}
    />
    <p className="text-2xl font-bold leading-none mb-4">
      {aboutMe}
    </p>

    <p>
      Currently, I am an Engineering Leader at Universe (acquired by Ticketmaster) in Toronto, where I have worked with a talented and diverse team in a range of problem spaces and technologies. I'm helping grow and develop our organization's structure and culture to support our incredibly talented team.
    </p>

    <p>
      As an early employee, I've helped Universe grow from a scrappy startup with less than ten employees to a global leader in the experiences market with offices around the world. There, I've collaborated with a worldwide team of developers, designers, product managers, and salespeople to find pragmatic solutions to a range of problems, within both the independent structure of Universe as well as the global Ticketmaster organization. As a technical leader, I evangelize new technologies and help with hiring decisions, interviewing candidates and promoting growth.
    </p>

    <p>
      I've led several important technical projects at Universe, including both domestic and international integrations with Ticketmaster, vital financial systems, search engines and more. I've championed the adoption of new technologies - such as building some of the first projects at Universe in Rust and Elixir, allowing us to solve some significant technical problems in ways previously not possible.
    </p>

    <p>
      I've been developing software for over a decade, since teaching myself as a child. While in high school, I ran a software development consulting company, working with businesses based in North America, Europe, and the Middle East. I released several hit web games before graduating high school, collectively played hundreds of thousands of times globally. In my teens I also had a well-reviewed app on the (then new) Android App Market and had a top featured app on the Blackberry App World.
    </p>

    <p>
      I have experience with Ruby, Javascript, and C# and have worked extensively with Rails, Ember, React, Redux, ElasticSearch, and Unity. I have built projects in Elixir, Rust, Node, Python, Java, ActionScript 3, and PHP.
    </p>

    <p className='Links'>
      <span className="font-bold text-lg">Links</span>
      <a className="ml-2 text-indigo-500 hover:text-indigo-600 transition duration-150 ease-in-out" href="https://github.com/connerfritz" target="_blank" rel="noreferrer">Github</a>
      <a className="ml-2 text-indigo-500 hover:text-indigo-600 transition duration-150 ease-in-out" href="https://twitter.com/connerfritz" target="_blank" rel="noreferrer">Twitter</a>
      <a className="ml-2 text-indigo-500 hover:text-indigo-600 transition duration-150 ease-in-out" href="https://medium.com/@connerfritz" target="_blank" rel="noreferrer">Medium</a>
      <a className="ml-2 text-indigo-500 hover:text-indigo-600 transition duration-150 ease-in-out" href="mailto:me@connerfritz.com?Subject=Hello%20Conner">Email</a>
      <a className="ml-2 text-indigo-500 hover:text-indigo-600 transition duration-150 ease-in-out" href="https://angel.co/connerfritz" target="_blank" rel="noreferrer">AngelList</a>
      <a className="ml-2 text-indigo-500 hover:text-indigo-600 transition duration-150 ease-in-out" href="https://www.linkedin.com/in/connerfritz" target="_blank" rel="noreferrer">LinkedIn</a>
    </p>
  </Layout>


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        aboutMe
        siteUrl
      }
    }
  }
`;

export default IndexPage
